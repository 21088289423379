<template>
  <div class="container destination-art-spaces-tab">
    <div class="row">
      <div class="col-12">
        <div v-if="!emptyContentData || !isFilterEmpty" class="app-data-filter-wrapper">
          <AppDataFilterOpenButton @click="$emit('open-filter')" />
        </div>

        <PageItemsGroup
          v-if="museumArtSpaces.length"
          :base-type="'art-spaces'"
          :has-more="getMore.museumArtSpaces"
          :items="museumArtSpaces"
          :title="'museums'"
          :type="'museumArtSpaces'"
          @load-more="loadMore"
        />
        <PageItemsGroup
          v-if="galleryArtSpaces.length"
          :base-type="'art-spaces'"
          :has-more="getMore.galleryArtSpaces"
          :items="galleryArtSpaces"
          :title="'galleries'"
          :type="'galleryArtSpaces'"
          @load-more="loadMore"
        />
        <PageItemsGroup
          v-if="historicalSites.length"
          :base-type="'historical-sites'"
          :has-more="getMore.historicalSites"
          :items="historicalSites"
          :title="'historical sites'"
          :type="'historicalSites'"
          @load-more="loadMore"
        />

        <DestinationTabNoResults :is-loading="loadingContentData" :is-no-results="isFilterEmpty && emptyContentData" />

        <div v-if="!isFilterEmpty && emptyContentData" class="without-result">
          <p class="without-result__title">0 results</p>
          <p class="without-result__text">Please reset or change your filter preferences.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import ArtSpaceDataFilterHelper from '@/helpers/data-filter/ArtSpaceDataFilterHelper';
import artSpaceBaseType from '@/enums/artSpaceBaseType';

import artSpacesQuery from '@/graphql/artSpace/ArtSpaces.query.gql';
import historicalSitesQuery from '@/graphql/historicalSite/HistoricalSites.query.gql';
import artSpacesPageFilterQuery from '@/graphql/artSpace/ArtSpacesPageFilter.query.gql';

import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import PageItemsGroup from '@/components/partials/PageItemsGroup';
import DestinationTabNoResults from '@/components/DestinationTabNoResults.vue';

const DATA_PER_PAGE = 6;
const AS_MUSEUM_DATA_TYPE = `${artSpaceBaseType.museum}ArtSpaces`;
const AS_GALLERY_DATA_TYPE = `${artSpaceBaseType.gallery}ArtSpaces`;
const HISTORICAL_SITES_DATA_TYPE = 'historicalSites';

export default {
  name: 'DestinationArtSpaceTab',
  components: {
    DestinationTabNoResults,
    AppDataFilterOpenButton,
    PageItemsGroup,
  },
  props: {
    destination: {
      type: Object,
      required: true,
    },
    filterValues: {
      type: Object,
      default: () => ({}),
    },
    filterValuesFromRoute: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      museumArtSpaces: [],
      galleryArtSpaces: [],
      historicalSites: [],
      loading: {
        museumArtSpaces: true,
        galleryArtSpaces: true,
        historicalSites: true,
      },
      getMore: {
        museumArtSpaces: false,
        galleryArtSpaces: false,
        historicalSites: false,
      },
      page: {
        museumArtSpaces: 1,
        galleryArtSpaces: 1,
        historicalSites: 1,
      },
    };
  },

  computed: {
    emptyContentData() {
      return !this.museumArtSpaces.length && !this.galleryArtSpaces.length && !this.historicalSites.length;
    },
    filterQueryVariables() {
      return ArtSpaceDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues, {
        ...ArtSpaceDataFilterHelper.setDestinationsToValuesManually([this.destination.slug]),
      });
    },
    loadingContentData() {
      return this.loading.museumArtSpaces || this.loading.galleryArtSpaces || this.loading.historicalSites;
    },
    isFilterEmpty() {
      return ArtSpaceDataFilterHelper.isAllValuesEmpty(this.filterValues);
    },
  },

  watch: {
    filterValues: {
      handler(newValues, oldValues) {
        if (Object.keys(oldValues).length) {
          const query = ArtSpaceDataFilterHelper.setFilterValuesToRouteQuery(newValues);
          this.$emit('update-route', query);
        }
        this.resetFetchDataOptions();
        this.fetchPageContentData();
      },
      deep: true,
    },
    filterValuesFromRoute: {
      handler(newValues, oldValues) {
        if (oldValues) {
          const initFilterValues = ArtSpaceDataFilterHelper.getFilterValuesFromRouteQuery(newValues);
          this.$emit('set-filter', { values: initFilterValues });
        }
      },
      deep: true,
    },
  },

  created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
      }
    );

    const initFilterValues = ArtSpaceDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.$emit('set-filter', { values: initFilterValues });
    this.getFilterData();
  },

  methods: {
    fetchPageContentData() {
      this.fetchArtSpaces(AS_MUSEUM_DATA_TYPE);
      this.fetchArtSpaces(AS_GALLERY_DATA_TYPE);
      this.fetchHistoricalSites();
    },
    fetchArtSpaces(type) {
      const baseType = type === AS_MUSEUM_DATA_TYPE ? artSpaceBaseType.museum : artSpaceBaseType.gallery;

      this.loading[type] = true;
      this.$apollo
        .query({
          query: artSpacesQuery,
          fetchPolicy: 'no-cache',
          variables: {
            baseType,
            limit: DATA_PER_PAGE,
            page: this.page[type],
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          this[type].push(...data.artSpaces.data);
          this.getMore[type] = data.artSpaces.hasMorePages;
          this.loading[type] = false;
        })
        .catch(() => {
          this.loading[type] = false;
        });
    },
    fetchHistoricalSites() {
      this.loading.historicalSites = true;
      this.$apollo
        .query({
          query: historicalSitesQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            page: this.page.historicalSites,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          this.historicalSites.push(...data.historicalSites.data);
          this.getMore.historicalSites = data.historicalSites.hasMorePages;
          this.loading.historicalSites = false;
        })
        .catch(() => {
          this.loading.historicalSites = false;
        });
    },
    getFilterData() {
      return this.$apollo
        .query({
          query: artSpacesPageFilterQuery,
          fetchPolicy: 'no-cache',
          variables: {
            skipDestinations: true,
          },
        })
        .then(({ data = {} }) => {
          const filterGroups = ArtSpaceDataFilterHelper.prepareFilterGroups(data);
          this.$emit('set-filter', { groups: filterGroups });
        });
    },
    loadMore(type) {
      if (this.getMore[type]) {
        this.page[type]++;

        if (type === HISTORICAL_SITES_DATA_TYPE) {
          this.fetchHistoricalSites();
        } else {
          this.fetchArtSpaces(type);
        }
      }
    },
    resetFetchDataOptions() {
      this.loading.museumArtSpaces = true;
      this.loading.galleryArtSpaces = true;
      this.loading.historicalSites = true;
      this.page.museumArtSpaces = 1;
      this.page.galleryArtSpaces = 1;
      this.page.historicalSites = 1;
      this.museumArtSpaces = [];
      this.galleryArtSpaces = [];
      this.historicalSites = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.destination-art-spaces-tab {
  padding-bottom: 40px;
}
.app-data-filter-wrapper {
  text-align: right;

  @media (max-width: 767px) {
    display: none;
  }
}
</style>
